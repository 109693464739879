import { MouseEventHandler, PropsWithChildren, ReactNode } from "react";
import Image from "next/image";

import { useIsMounted } from "@enkor/toss-slash";
import {
  getLetsfipLegalDocumentLink,
  SOCIAL_LINKS,
} from "@enkor/external-links";

import { grayScale } from "@enkor/design/colors";
import {
  ChevronLeftIcon,
  FooterBlogIcon,
  FooterInstagramIcon,
  FooterStaySymbolIcon,
  FooterTiktokIcon,
} from "@enkor/design/icons";
import { useAuthSession } from "@domains/auth/hooks/useAuthSession";
import {
  COMPANY_CONTACTS,
  FOOTER_PHRASES,
} from "@domains/common/constants/footer";
import { NAVIGATION_ROUTE } from "@domains/common/constants/navigationRoute";
import WebViewBridge from "@logics/utils/webViewBridge";

import * as S from "./AppLayout.style";

const LEFT_ICON = {
  CHEVRON_LEFT: <ChevronLeftIcon strokeColor={grayScale.white} />,
} as const;

type LeftIconType = keyof typeof LEFT_ICON;

interface AppLayoutProps {
  navbar?: {
    iconType?: LeftIconType; // 몇가지 올 수 있음. 전달하지 않으면 Logo
    onClickIcon?: () => void;
    title?: string;
    renderRightButtonGroup?: () => ReactNode;
  };
  hideNavbar?: boolean;
  hideFooter?: boolean;
}

/**
 * AppLayout
 *
 */
const AppLayout = ({
  hideNavbar = false,
  hideFooter = false,
  navbar,
  children,
}: PropsWithChildren<AppLayoutProps>) => {
  const isMounted = useIsMounted();
  const shouldShowFooter = WebViewBridge.isInAppWebView() ? false : !hideFooter; // NOTE: 웹뷰에서는 hideFooter와 관련 없이 푸터를 보여주지 않음

  const { logout } = useAuthSession();

  const handleLogoClick: MouseEventHandler<HTMLAnchorElement> = (e) => {
    if (WebViewBridge.isInAppWebView()) {
      e.preventDefault();
      WebViewBridge.getInstance().navigateToMain();
    }
  };

  return (
    <S.Layout>
      <S.Header>
        {!hideNavbar && (
          <S.Navbar>
            {navbar?.iconType ? (
              <S.LeftButton type="button" onClick={navbar?.onClickIcon}>
                {LEFT_ICON[navbar.iconType]}
              </S.LeftButton>
            ) : (
              <S.LogoLink
                href={NAVIGATION_ROUTE.EVENT_LIST.HREF}
                onClick={handleLogoClick}
              >
                <Image fill src="/logo/fip-logo-pink.png" alt="main-logo" />
              </S.LogoLink>
            )}
            {!!navbar?.title && <S.PageTitle>{navbar?.title}</S.PageTitle>}
            {navbar?.renderRightButtonGroup && isMounted && (
              <S.RightButtonGroup>
                {navbar?.renderRightButtonGroup()}
              </S.RightButtonGroup>
            )}
          </S.Navbar>
        )}
      </S.Header>

      <S.ContentSection $hasNavbar={!hideNavbar} $hasFooter={shouldShowFooter}>
        {children}
      </S.ContentSection>

      {shouldShowFooter && (
        <S.FooterSection>
          <S.EnkorLogoWrapper>
            <Image
              fill
              src="/logo/enkorwithus_logo_with_text.png"
              alt="enkorwithus-logo"
            />
          </S.EnkorLogoWrapper>
          <S.Copyright name="s">{FOOTER_PHRASES.COPYRIGHT}</S.Copyright>
          <S.LinkList>
            <S.ExternalLink
              href={getLetsfipLegalDocumentLink("PRIVACY_POLICY", "en")}
              target="_blank"
            >
              Privacy Policy
            </S.ExternalLink>
            <S.LinkDivider>•</S.LinkDivider>
            <S.ExternalLink
              href={getLetsfipLegalDocumentLink("TERMS_OF_CONDITIONS", "en")}
              target="_blank"
            >
              Host/Guest policy
            </S.ExternalLink>
            <S.LinkDivider>•</S.LinkDivider>
            <S.LogoutButton type="button" onClick={logout}>
              Logout
            </S.LogoutButton>
          </S.LinkList>
          <S.FooterDivider />
          <S.SocialLinkWrapper>
            <S.SocialLink href={SOCIAL_LINKS.ENKOR_STAY} target="_blank">
              <FooterStaySymbolIcon size={18} strokeColor={grayScale[700]} />
            </S.SocialLink>
            <S.SocialLink href={SOCIAL_LINKS.ENKOR_BLOG} target="_blank">
              <S.ColoredIcon>
                <FooterBlogIcon size={18} />
              </S.ColoredIcon>
            </S.SocialLink>
            <S.SocialLink href={SOCIAL_LINKS.INSTAGRAM_FIP} target="_blank">
              <S.ColoredIcon>
                <FooterInstagramIcon size={18} strokeColor={grayScale[700]} />
              </S.ColoredIcon>
            </S.SocialLink>
            <S.SocialLink href={SOCIAL_LINKS.TIKTOK} target="_blank">
              <S.ColoredIcon>
                <FooterTiktokIcon size={18} />
              </S.ColoredIcon>
            </S.SocialLink>
          </S.SocialLinkWrapper>
          <S.CompanyInfo name="xs">
            {`Business registration number : ${COMPANY_CONTACTS.BUSINESS_REGISTRATION_NUMBER}\n`}
            {`CEO : ${COMPANY_CONTACTS.CEO} | TEL : ${COMPANY_CONTACTS.TEL_NUMBER}\n`}
            {`Mail order business report number: ${COMPANY_CONTACTS.ADDRESS}`}
          </S.CompanyInfo>
        </S.FooterSection>
      )}
    </S.Layout>
  );
};

export default AppLayout;
