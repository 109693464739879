import Link from "next/link";

import styled from "styled-components";

import { APP_SIZE, FOOTER_SIZE, HEADER_SIZE } from "@styles/variables";
import { getOpacityColor, grayScale } from "@enkor/design/colors";
import { Z_INDEX } from "@enkor/design/variables";
import { Typo } from "@enkor/design/components";

export const Layout = styled.main`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  /* HACK: Height가 body부터 명확히 정해져 있지 않기 때문에 하위 요소에도 모두 min-height view height를 적용해주어야 컨텐츠 크기가 작을 때도 꽉 채워진 화면을 볼 수 있음. */
  min-height: 100vh;
  min-height: calc((var(--vh, 1vh) * 100));
`;

export const Header = styled.header`
  position: fixed;
  top: 0;
  left: 50%;
  z-index: ${Z_INDEX.NAVBAR};

  width: 100%;
  max-width: ${APP_SIZE.MAX_WIDTH}px;

  background-color: ${getOpacityColor(grayScale[200], 0.7)};

  transform: translateX(-50%);
`;

export const Navbar = styled.nav`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;

  height: ${HEADER_SIZE.HEIGHT}px;
  padding: 0 16px;

  h1 {
    position: absolute;
    top: 50%;
    left: 50%;

    color: ${grayScale.white};

    transform: translate(-50%, -50%);
  }
`;

export const LeftButton = styled.button`
  width: 24px;
  height: 24px;
`;

export const LogoLink = styled(Link)`
  position: relative;

  width: 35px;
  height: 20px;
`;

export const PageTitle = styled.h1`
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
`;

export const RightButtonGroup = styled.div`
  position: relative;
  display: flex;
`;

export const ContentSection = styled.section<{
  $hasNavbar?: boolean;
  $hasFooter: boolean;
}>`
  width: 100%;
  height: 100%;
  /* HACK: Height가 body부터 명확히 정해져 있지 않기 때문에 하위 요소에도 모두 min-height view height를 적용해주어야 컨텐츠 크기가 작을 때도 꽉 채워진 화면을 볼 수 있음. */
  min-height: 100vh;
  min-height: ${({ $hasFooter }) =>
    $hasFooter ? `calc(100vh - ${FOOTER_SIZE.HEIGHT}px)` : "100vh"};
  padding-top: ${({ $hasNavbar }) =>
    $hasNavbar ? `${HEADER_SIZE.HEIGHT}px` : 0};
`;

export const FooterSection = styled.section`
  margin-top: auto;
  padding: 24px 16px;
  border-top: 1px solid ${grayScale.main};

  background-color: ${grayScale[200]};
`;

export const EnkorLogoWrapper = styled.div`
  position: relative;

  width: 170px;
  height: 28px;
  margin-bottom: 8px;
`;

export const Copyright = styled(Typo.Text)`
  margin-bottom: 22px;
`;

export const LinkList = styled.div``;

export const ExternalLink = styled(Link)`
  color: ${grayScale.white};
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  white-space: nowrap;
  word-break: keep-all;
`;

export const LinkDivider = styled.span`
  display: inline-block;

  width: 20px;

  font-size: 14px;
  line-height: 20px;
  text-align: center;

  cursor: default;
`;

export const LogoutButton = styled.button`
  color: ${grayScale[700]};
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
`;

export const FooterDivider = styled.hr`
  width: 100%;
  height: 1px;
  margin: 22px 0 20px;
  border: 0;

  background-color: ${grayScale.main};
`;

export const SocialLinkWrapper = styled.div`
  display: flex;
  gap: 14px;

  margin-bottom: 16px;
`;

export const SocialLink = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 32px;
  height: 32px;
  border-radius: 50%;

  background-color: ${grayScale.main};
`;

export const ColoredIcon = styled.div`
  width: 18px;
  height: 18px;

  fill: ${grayScale[700]};
  stroke-width: 0px;

  * {
    fill: ${grayScale[700]};
  }
`;

export const CompanyInfo = styled(Typo.Text)`
  color: ${grayScale[700]};
`;
